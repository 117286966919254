import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { CalendarEvent } from './useCalendarEvents';
import moment, { Moment } from 'moment';
import { Buttons } from '../../../toolympus/components/primitives';
import { IconButton, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

interface DayProps {
  isToday?: boolean;
  isSelectedMonth?: boolean;
}

const DayLabel = styled.span`
  font-size: 0.9rem;
  opacity: 0.75;
  display: block;
`;

const EventItem = styled.div`

`;

const Day = styled.div<DayProps>`
  padding: 3px;
  background: ${props => props.isToday
    ? `${props.theme.palette.primary.main}20`
    : props.isSelectedMonth
      ? "transparent"
      : props.theme.palette.grey[200]};

  border-left: 1.5px solid ${props => props.theme.palette.grey[200]};
  border-top: 1.5px solid ${props => props.theme.palette.grey[200]};

  &:nth-child(7n) {
    border-right: 1.5px solid ${props => props.theme.palette.grey[200]};
  }
`;

const CalendarInner = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1.5px solid ${props => props.theme.palette.grey[200]};
  flex: 1 0 auto;
`;

const CalendarOuter = styled.div`
  & .title {
    text-transform: capitalize;
  }
  display: flex;
  flex-flow: column;
  align-items: stretch;
  
  gap: 10px;
  flex: 1 0 auto;
`;

interface Props {
  events: CalendarEvent[];
  create: () => void;
}

export const CalendarEventsView = (props: Props) => {
  const [monthStart, setMonthStart] = useState<Moment>(moment().set("D", 1));

  const x = useMemo(() => {
    let startDate = monthStart.clone();
    if(startDate.day() !== 1) {
      startDate = startDate.add(1-startDate.day(), "d");
    }
    let endDate = monthStart.clone().add(1, "M").add(-1, "d");
    if(endDate.day() !== 0) {
      endDate = endDate.add(7-endDate.day(), "d");
    }

    const days = [startDate];
    let d = startDate.clone().add(1, "d");
    while(d.isBefore(endDate)) {
      days.push(d);
      d = d.clone().add(1, "d");
    }
    days.push(endDate);

    const today = moment().format("yyyy-MM-DD");
    const selectedMonth = monthStart.format("yyyy-MM");

    const richDays = days.map(d => {
      const str = d.format("yyyy-MM-DD");

      const events = props.events.filter(e => e.start_datetime.startsWith(str) || (
        e.start_datetime < str && e.end_datetime >= str
      ));

      return {
        date: d,
        str,
        label: d.format("DD, dd"),
        isToday: str === today,
        isSelectedMonth: str.substring(0,7) === selectedMonth,
        events,
      }
    })

    return {
      startDate,
      endDate,
      days: richDays,
    };

  }, [monthStart, props.events]);

  return (
    <CalendarOuter>
      <Buttons>
        <Typography variant="h6" className="title">
          {monthStart.format("MMMM, yyyy")}
        </Typography>

        <IconButton size="small" onClick={() => setMonthStart(x => x)}>
          <ArrowBackIos />
        </IconButton>
        <IconButton size="small">
          <ArrowForwardIos />
        </IconButton>
      </Buttons>

        i has events {props.events.length}

      <CalendarInner>
        {x.days.map(d => (
          <Day key={d.str} isToday={d.isToday} isSelectedMonth={d.isSelectedMonth}>
            <DayLabel className="date">{d.label}</DayLabel>
            {d.events.map(e => (
              <EventItem key={e._id}>{moment(e.start_datetime).format("hh:mm")}: {e.title}</EventItem>
            ))}
          </Day>
        ))}
      </CalendarInner>
    </CalendarOuter>
  );
}
