import { useMemo, useState } from "react";
import { useNewItem, useEditItem2, withActionOnSaveItem } from "../../../toolympus/api/useNewItem";
import { useChunkedLoadedList, useLoadedList } from "../../../toolympus/hooks/useLoadedList";
import { mergeSchema, useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { useThrottledCapture } from "../../../toolympus/components/primitives";
import { useDictionaries } from "../../../toolympus/hooks/useDictionaries";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { useConfRoomsSelection } from "./useConfRoomsConfiguration";
import moment from "moment";
import { DateInternalFormat, DateTimeInternalFormat, TimeInternalFormat } from "../../../toolympus/api/datetimeUtil";

export interface CalendarEvent {
  _id: number;
  title: string;
  description?: any;
  start_datetime: string;
  
  end_datetime: string;
  duration_option?: string;
  owners?: string[];
  equipment?: string[];
  case_id?: string | null;
  conf_room_id?: string;
}

const ApiPath = "/api/organization/calendar/event";

export const DayStartTime = "00:00:00";
export const DayEndTime = "23:59:00";

export const useMyEmployeeId = () => {
  const dicts = useDictionaries();
  const employeesDict = dicts["Employees"];
  const { user } = useUser();

  return employeesDict?.records?.find(e => e.extra?.user_id === user?._id)?.code;
}

// const dateStart = (d: MomentInput) => {
//   return moment(d).set("hour", 0).set("minutes", 0).set("seconds", 0).format(DateTimeInternalFormat);
// }
// const dateEnd = (d: MomentInput) => {
//   return moment(d).set("hour", 23).set("minutes", 59).set("seconds", 0).format(DateTimeInternalFormat);
// }

export const DurationOptions = {
  start_end: "start_end",
  till_day_end: "till_day_end",
  full_day: "full_day",
}

const processEventChanges = (item: Partial<CalendarEvent>, changes: Partial<CalendarEvent>): Partial<CalendarEvent> => {
  let result = changes;

  const newStartS = changes.start_datetime && !changes.start_datetime.toLowerCase().includes("invalid") ? changes.start_datetime : null;
  const oldStartS = item.start_datetime && !item.start_datetime.toLowerCase().includes("invalid") ? item.start_datetime : null;

  console.log("CAL: processing changes", newStartS);

  if(newStartS) {
    const endS = changes.end_datetime || item.end_datetime;
    const end = endS ? moment(endS) : null;
    const start = moment(newStartS);
    const oldStart = oldStartS ? moment(oldStartS) : null;

    
    if(!oldStart) {
      console.log("start DATE just appeared")
      console.log("set end date to same stuff + 1h");
      result = {
        ...result,
        end_datetime: moment(start).add(1, "hour").format(DateTimeInternalFormat),
      }
    } else if(!end) {
      console.log("end was empty -> treat as same date");
      result = {
        ...result,
        end_datetime: moment(start).add(1, "hour").format(DateTimeInternalFormat),
      }
    } else if(oldStart.format(DateInternalFormat) === start.format(DateInternalFormat)) {
      console.log("only start TIME changed");
      if(oldStart.format(DateInternalFormat) === end.format(DateInternalFormat)) {
        console.log("-- old start and end DATEs match");
        const oldDiffInMinutes = end.diff(oldStart, "m");
        const projectedEnd = moment(start).add(oldDiffInMinutes, "m");
        if(projectedEnd.format(DateInternalFormat) === start.format(DateInternalFormat)) {
          console.log("--- preserving minutes diff leaves us in the same date");
          result = {
            ...result,
            end_datetime: projectedEnd.format(DateTimeInternalFormat),
          };
        } else {
          console.log("--- preserving minutes diff moves us outside the same date -> stick to day end");
          result = {
            ...result,
            end_datetime: `${start.format(DateInternalFormat)} ${DayEndTime}`,
          };
        }
      } else {
        console.log("-- old start and end DATEs differ");
        // -> do nothing - end is detached
      }
    } else {
      console.log("start DATE (and possibly TIME) changed");
      if(oldStart.format(DateInternalFormat) === end.format(DateInternalFormat)) {
        console.log("-- same start & end DATEs");
        const oldDiffInMinutes = end.diff(oldStart, "m");
        const projectedEnd = moment(start).add(oldDiffInMinutes, "m");
        if(projectedEnd.format(DateInternalFormat) === start.format(DateInternalFormat)) {
          // preserving minutes diff leaves us in the same date
          result = {
            ...result,
            end_datetime: projectedEnd.format(DateTimeInternalFormat),
          };
        } else {
          // preserving minutes diff moves us outside the same date -> stick to day end
          result = {
            ...result,
            end_datetime: `${start.format(DateInternalFormat)} ${DayEndTime}`,
          };
        }
      } else {
        console.log("-- different start & end DATEs");
        // -> preserve day diff
        const oldDiffInDays = end.diff(oldStart, "d");
        const projectedEnd = moment(start).add(oldDiffInDays, "d");
        result = {
          ...result,
          end_datetime: `${projectedEnd.format(DateInternalFormat)} ${end.format(TimeInternalFormat)}`,
        }
      }
    }
  }

  return result;
}

export const useCalendarEvents = () => {
  const [filter, setFilter] = useState<string>("");
  const appliedFilter = useThrottledCapture(filter);

  const myEmployeeId = useMyEmployeeId();

  const [myOnly, setMyOnly] = useState<boolean>(false);
  const [showPast, setShowPast] = useState<boolean>(false);

  const data = useLoadedList<CalendarEvent>(ApiPath, {
    noView: true,
    extraParams: {
      search: appliedFilter,
      owner_id: myOnly ? myEmployeeId : "",
    },
  })
  const { schema: schemaBase } = useSingleSchema(`${ApiPath}/uiconfig`);

  const confRooms = useConfRoomsSelection();

  const schema = useMemo(() => {
    return mergeSchema(schemaBase, { conf_room_id: confRooms.selectorSchema });
  }, [schemaBase, confRooms.selectorSchema])

  const newItem = useNewItem<Partial<CalendarEvent>, CalendarEvent>(ApiPath, {
    title: "",
    duration_option: "start_end",
    owners: myEmployeeId ? [myEmployeeId] : [],
    equipment: [],
  }, {
    onChange: processEventChanges,
  });

  const editItem = useEditItem2<CalendarEvent>({
    getApiPath: r => `${ApiPath}/${r._id}`,
    onChange: processEventChanges,
  });

  const past = useChunkedLoadedList<CalendarEvent>(ApiPath, {
    noView: true,
    extraParams: {
      search: appliedFilter,
      owner_id: myOnly ? myEmployeeId : "",
      past: "only",
    },
    noLoad: !showPast,
    sorting: {
      defaultSort: { field: "start_datetime", direction: "desc" },
    }
  });

  const [confRoomsFilter, setConfRoomsFilter] = useState<string[]>([]);

  const filtered = useMemo(() => {
    if(!confRoomsFilter.length) {
      return data.data;
    } else {
      return data.data.filter(e => e.conf_room_id && confRoomsFilter.includes(e.conf_room_id));
    }

  }, [data.data, confRoomsFilter]);
  

  return {
    ...data,
    data: filtered,
    filter: {
      filter,
      setFilter,
      myOnly,
      setMyOnly,
      showPast,
      setShowPast,

      confRoomsFilter,
      setConfRoomsFilter,
    },
    past,
    schema,
    newItem: withActionOnSaveItem(newItem, () => { data.reload(); }),
    editItem: withActionOnSaveItem(editItem, () => { data.reload(); }),

    rooms: confRooms.data,
  }
}
