import React, { useState } from 'react';
import { ButtonOptionPicker, Form, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SearchField, useWindowHotkey } from '../../../toolympus/components/primitives';
import { Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCalendarEvents } from './useCalendarEvents';
import { EditCalendarEventDialog } from './EditCalendarEventDialog';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';
import moment from 'moment';
import { TableForFields, useFormats } from '../../../toolympus/components/schemed';
import { InsetPanel } from '../../Common/UI';
import { ConfRoomsActivatorPicker } from './ConfRoomsActivatorPicker';
import { Add, CalendarMonthOutlined, ListOutlined } from '@mui/icons-material';
import { CalendarEventsView } from './CalendarEventsView';

interface Props {
  
}


export const EventsCalendarPage = (props: Props) => {
  const data = useCalendarEvents();
  const formats = useFormats();

  const [mode, setMode] = useState<"list" | "calendar">("list");

  useWindowHotkey("alt+n", () => data.newItem.startEditing());

  return (
    <>
      <Form
        title="Календарь"
        fitFullHeight
        formBodyProps={{ style: { flex: "1 0 auto" }}}
        headerItems={<>
            <IconButton color='primary' size="small" onClick={() => () => data.newItem.startEditing()}><Add /></IconButton>
            <ButtonOptionPicker
              options={[
                ["мои", "my"],
                ["все", "all"],
              ]}
              selected={data.filter.myOnly ? "my" : "all"}
              setSelected={s => data.filter.setMyOnly(s === "my" ? true : false)}
              />

            <ButtonOptionPicker
              options={[
                ["предстоящие", "future"],
                ["прошедшие", "past"],
              ]}
              selected={data.filter.showPast ? "past" : "future"}
              setSelected={s => data.filter.setShowPast(s === "past" ? true : false)}
              />

            {mode === "list" &&
              <IconButton
                onClick={() => {
                  setMode("calendar");
                  if(data.filter.showPast) {
                    data.filter.setShowPast(false);
                  }
                }}
                size="small"
                color="primary">
                <CalendarMonthOutlined />
              </IconButton>}

            {mode === "calendar" &&
              <IconButton
                onClick={() => {
                  setMode("list");
                }}
                size="small"
                color="primary">
                <ListOutlined />
              </IconButton>}
              
            <OccupyFreeSpace />
            {data.isLoading && <LoadingIndicator sizeVariant="m" />}
            <SearchField
              filter={data.filter.filter}
              setFilter={data.filter.setFilter}
              noButton
              autoFocus
              />
            
            <Link to="/org/conf-rooms">
              <Button size="small" color="primary">
                переговорки
              </Button>
            </Link>
            <PluggableDocumentationButton documentationKey="org_events" />
            
            </>}>

          <InsetPanel>
            <ConfRoomsActivatorPicker
              available={data.rooms}
              selected={data.filter.confRoomsFilter}
              setSelected={data.filter.setConfRoomsFilter}
              />
          </InsetPanel>
          
          

          {mode === "list" && 
            <TableForFields
              tailAnchor={data.filter.showPast ? data.past.limitTrigger.pagingAnchor : undefined}
              fields={[
                ["title"],
                ["casenbr"],
                ["conf_room_id"],
                ["start_date", { label: "Дата" }],
                ["start_datetime"],
                ["end_datetime"],
                ["confirmation"],
              ]}
              data={!data.filter.showPast ? data.data : data.past.data}
              schema={data.schema}
              fieldElement={f => {
                switch(f) {
                  case "title":
                    return (r,s,o) => <PseudoLink2 onClick={() => data.editItem.startEditing({ ...r })}>{o}</PseudoLink2>;
                  case "start_date":
                    return (r) => formats.formatDate(r.start_datetime);
                  case "start_datetime":
                    return r => moment(r.start_datetime).format("HH:mm");
                  case "end_datetime":
                    return (r,s,o) => {
                      const start = moment(r.start_datetime);
                      const end = moment(r.end_datetime);
                      if(start.isSame(end, "date")) {
                        return end.format("HH:mm")
                      } else {
                        return `${end.format("HH:mm")} ! ${formats.formatDate(r.end_datetime)}`
                      }
                    }
                }
              }}
              sorting={!data.filter.showPast ?  data.sorting : undefined}
              />}

          {mode === "calendar" &&
            <CalendarEventsView
              events={data.data}
              create={() => data.newItem.startEditing()}
              />}
          
      </Form>
      

      <EditCalendarEventDialog
        data={data.newItem}
        schema={data.schema}
        />
      
      <EditCalendarEventDialog
        data={data.editItem}
        schema={data.schema}
        />
    </>
  );
}
